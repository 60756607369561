// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

export const AVATARS = [
  {
    name: "bear",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/animals_square/bear.png",
  },
  {
    name: "bird",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/animals_square/bird.png",
  },
  {
    name: "bird2",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/animals_square/bird2.png",
  },
  {
    name: "dog",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/animals_square/dog.png",
  },
  {
    name: "giraffe",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/animals_square/giraffe.png",
  },
  {
    name: "hedgehog",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/animals_square/hedgehog.png",
  },
  {
    name: "hippo",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/animals_square/hippo.png",
  }
];

export const STICKERS = [
  {
    name: "cute",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/chat/v1/sticker-1.png"
  },
  {
    name: "angry",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/chat/v1/sticker-2.png"
  },
  {
    name: "sad",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/chat/v1/sticker-3.png"
  },
  {
    name: "happy",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/chat/v1/sticker-4.png"
  },
  {
    name: "surprised",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/chat/v1/sticker-5.png"
  },
  {
    name: "cool",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/chat/v1/sticker-6.png"
  },
  {
    name: "love",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/chat/v1/sticker-7.png"
  },
  {
    name: "rocket",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/chat/v1/sticker-8.png"
  },
  {
    name: "confetti",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/chat/v1/sticker-9.png"
  },
  {
    name: "camera",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/chat/v1/sticker-10.png"
  } 
]